import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, StaticQuery } from "gatsby"

const AboutMe = () => {
  return (
    <div
      id="uebermich"
      className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 pt-16 sm:pt-20 bg-white"
    >
      <h1 className="w-full text-4xl md:text-5xl text-center mt-1 mb-6 text-gray-800">
        Über mich
      </h1>
      <div className="text-center xl:h-96">
        <StaticQuery
          query={graphql`
            query {
              eduard: file(relativePath: { eq: "eduard_smolarczyk.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    width: 500
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
            }
          `}
          render={data => (
            <GatsbyImage
              image={data.eduard.childImageSharp.gatsbyImageData}
              alt="Portrait von Eduard Smolarczyk"
              className="max-h-96 w-full mb-8 lg:my-16 rounded-full border-radius-fix mx-auto md:float-left md:mr-12 xl:my-0 max-w-sm"
            />
          )}
        />

        <div className="xl:h-full xl:items-center xl:flex">
          <div className="space-y-4 text-left">
            <p>
              Mein Name ist Eduard Smolarczyk. Ich plane und realisiere die
              Metallbauprojekte meiner Kunden in Marburg, Kirchhain,
              Stadtallendorf, Biedenkopf, Homberg (Ohm), Frankenberg, Gießen und
              Umgebung.
            </p>
            <p>
              Ich liefere Hochbalkone, Terrassen, Geländer, Treppen, Vordächer,
              Tore, Zaunanlagen und diversen Industriebedarf
              (Dachabsturzsicherung etc.) aus hochwertigen Rohstoffen (Stahl,
              Aluminium, Edelstahl etc.) mit präziser handwerklicher Sorgfalt.
            </p>
            <p>
              Im Vordergrund steht die persönliche und erstklassige Beratung. In
              mehreren Terminen gestalten wir gemeinsam Ihr Vorhaben. Es beginnt
              mit Erstskizze, worauf eine digitale Zeichnung inklusive
              Darstellung folgt. Abschließend planen wir die Produktion und den
              Montagetermin. Für jeden Bedarf bieten wir eine passende und
              preiswerte Lösung.
            </p>
            <p>
              Daraufhin fertigen meine polnischen Geschäftspartner, welche auf
              30 Jahre Erfahrung im Metallbau zurückblicken, fachmännische und
              kosteneffiziente Metallarbeiten aus hochwertigen Rohstoffen. Sie
              bekommen ein Rundum-Sorglos-Paket inklusive Lieferung und Montage.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
