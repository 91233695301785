import React, { Component } from "react"

import "./../styles/contactFormModal.css"
class ContactFormModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpened: this.props.modalOpened,
      error: this.props.error,
    }
  }

  handleClick = () => {
    this.props.clickCallback(this.props.modalOpened)
  }

  render() {
    return (
      <div
        className={
          "modal flex flex-col fixed top-0 z-40 w-full bg-blue-1 bg-opacity-95 top-0 left-0 items-center justify-center " +
          (this.props.modalOpened ? " modal-opened" : "modal-closed")
        }
      >
        <div className="container max-w-screen-md xl:max-w-screen-md mx-auto px-6 space-y-8 text-center">
          {(() => {
            if (this.props.error) {
              return (
                <div>
                  <p className="text-white font-bold sm:text-4xl text-6xl">
                    Ups...
                  </p>
                  <p className="text-white text-base mt-8">
                    Es ist ein unbekannter Fehler aufgetreten.
                    <br />
                    Ihre Kontaktanfrage konnte leider nicht abgesandt werden.
                  </p>
                  <p className="text-white text-base mt-6">
                    Bitte kontaktieren Sie mich stattdessen per E-Mail oder
                    Telefon.
                  </p>
                </div>
              )
            } else {
              return (
                <div className="space-y-8">
                  <p className="text-white font-bold sm:text-4xl text-6xl">
                    Vielen Dank!
                  </p>
                  <p className="text-white text-base">
                    Sie werden in Kürze eine Bestätigungs-E-Mail bekommen.
                    <br />
                    In der Regel melde ich mich innerhalb von ein bis zwei
                    Werktagen bei Ihnen.
                  </p>
                </div>
              )
            }
          })()}

          <button
            className="inline-flex items-center px-5 py-2.5 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150 bg-blue-2 focus:outline-none"
            onClick={this.handleClick}
          >
            Schließen
          </button>
        </div>
      </div>
    )
  }
}

export default ContactFormModal
