import React, { Component } from "react"
import AppContext from "./AppContext"
import ContactForm from "./contactForm"
class Contact extends Component {
  render() {
    return (
      <div className="bg-white pt-0 sm:pt-20">
        <div id="kontakt" className="bg-white pt-16 sm:pt-0 sm:bg-blue-1">
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 sm:pt-16">
            <h2 className="w-full text-4xl md:text-5xl text-center mb-5 sm:mb-4 text-gray-800 sm:text-white">
              Kon&shy;tak&shy;tie&shy;ren Sie mich!
            </h2>

            <p className="mb-8 text-center text-black sm:text-white">
              Zögern Sie nicht mich zu kontaktieren. Ich freue mich schon darauf
              Ihr Projekt umzusetzen!
            </p>
          </div>

          <div className="container max-w-screen-lg xl:max-w-screen-xl sm:mx-auto sm:px-6 sm:pb-20">
            <div className="grid grid-cols-1 md:grid-cols-10 sm:shadow-lg">
              <div className="col-span-4 xl:col-span-3 bg-gradient-to-t from-blue-3 to-blue-4 md:bg-gray-1 text-white flex items-center justify-center px-7 py-11">
                <div className="text-center grid gap-8 md:gap-11 grid-cols-1 xs:grid-cols-2  md:grid-cols-1 items-center">
                  <div>
                    <svg
                      className="w-11 h-11 inline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 360 512"
                    >
                      <path
                        d="M256,0C156.75,0,76,80.75,76,180a179.62,179.62,0,0,0,26.87,94.65L245.75,504.91A15,15,0,0,0,258.5,512h.12a15,15,0,0,0,12.75-7.29L410.61,272.22A179.78,179.78,0,0,0,436,180C436,80.75,355.25,0,256,0ZM384.87,256.82,258.27,468.19,128.37,258.85A149.79,149.79,0,0,1,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180A149.9,149.9,0,0,1,384.87,256.82Z"
                        transform="translate(-76)"
                        fill="#fff"
                      />
                      <path
                        d="M256,90a90,90,0,1,0,90,90A90.1,90.1,0,0,0,256,90Zm0,150.2A60.2,60.2,0,1,1,316.1,180,60.24,60.24,0,0,1,256,240.2Z"
                        transform="translate(-76)"
                        fill="#fff"
                      />
                    </svg>
                    <p className="mt-2">Unter den Krohgärten 20</p>
                    <p className="mt-0">35288 Wohratal</p>
                  </div>
                  <div className="">
                    <svg
                      className="w-11 h-11 inline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 360"
                    >
                      <path
                        d="M467,76H45A45.07,45.07,0,0,0,0,121V391a45.06,45.06,0,0,0,45,45H467a45.05,45.05,0,0,0,45-45V121A45,45,0,0,0,467,76Zm-6.3,30L287.82,278a45,45,0,0,1-63.67,0L51.3,106ZM30,384.89V127.12l129.64,129ZM51.32,406,180.91,277.24,203,299.18a75,75,0,0,0,106,0l22.08-22L460.68,406ZM482,384.89,352.36,256.08,482,127.12Z"
                        transform="translate(0 -76)"
                        fill="#fff"
                      />
                    </svg>
                    <a className="block" href="mailto:mail@e-smolarczyk.de">
                      mail@e-smolarczyk.de
                    </a>
                  </div>
                  <div className="xs:col-span-2 md:col-span-1">
                    <svg
                      className="w-11 h-11 inline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 473.12 473.81"
                    >
                      <path
                        d="M374.46,293.51c-9.7-10.1-21.4-15.5-33.8-15.5s-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4-3.6-1.8-7-3.5-9.9-5.3-29.6-18.8-56.5-43.3-82.3-75-12.5-15.8-20.9-29.1-27-42.6,8.2-7.5,15.8-15.3,23.2-22.8,2.8-2.8,5.6-5.7,8.4-8.5,21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5-6-6.2-12.3-12.6-18.8-18.6-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7l-.2.2-34,34.3a73.16,73.16,0,0,0-21.7,46.5c-2.4,29.2,6.2,56.4,12.8,74.2,16.2,43.7,40.4,84.2,76.5,127.6,43.8,52.3,96.5,93.6,156.7,122.7,23,10.9,53.7,23.8,88,26,2.1.1,4.3.2,6.3.2,23.1,0,42.5-8.3,57.7-24.8.1-.2.3-.3.4-.5,5.2-6.3,11.2-12,17.5-18.1,4.3-4.1,8.7-8.4,13-12.9,9.9-10.3,15.1-22.3,15.1-34.6s-5.3-24.3-15.4-34.3l-54.9-55.1Zm35.8,105.3c-.1,0-.1.1,0,0-3.9,4.2-7.9,8-12.2,12.2a263,263,0,0,0-19.3,20c-10.1,10.8-22,15.9-37.6,15.9-1.5,0-3.1,0-4.6-.1-29.7-1.9-57.3-13.5-78-23.4C202,396,152.26,357.11,111,307.81c-34.1-41.1-56.9-79.1-72-119.9-9.3-24.9-12.7-44.3-11.2-62.6a45.9,45.9,0,0,1,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1,6.3,0,11.4,3.8,14.6,7l.3.3c6.1,5.7,11.9,11.6,18,17.9,3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3c10.6,10.6,10.6,20.4,0,31-2.9,2.9-5.7,5.8-8.6,8.6-8.4,8.6-16.4,16.6-25.1,24.4-.2.2-.4.3-.5.5-8.6,8.6-7,17-5.2,22.7l.3.9c7.1,17.2,17.1,33.4,32.3,52.7l.1.1c27.6,34,56.7,60.5,88.8,80.8A136.54,136.54,0,0,0,255,345c3.6,1.8,7,3.5,9.9,5.3.4.2.8.5,1.2.7a21.68,21.68,0,0,0,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5s11.3,3.9,14.4,7.3l55.3,55.3c10.3,10.2,10.3,20.7.1,31.3Z"
                        transform="translate(-0.35 0)"
                        fill="#fff"
                      />
                      <path
                        d="M256.06,112.71a128.7,128.7,0,0,1,104.8,104.8,13.42,13.42,0,0,0,13.3,11.2,17.85,17.85,0,0,0,2.3-.2,13.51,13.51,0,0,0,11.1-15.6,155.62,155.62,0,0,0-126.8-126.8,13.57,13.57,0,0,0-15.6,11,13.35,13.35,0,0,0,10.9,15.6Z"
                        transform="translate(-0.35 0)"
                        fill="#fff"
                      />
                      <path
                        d="M473.26,209A256.25,256.25,0,0,0,264.46.21a13.48,13.48,0,1,0-4.4,26.6,228.88,228.88,0,0,1,186.6,186.6,13.42,13.42,0,0,0,13.3,11.2,17.85,17.85,0,0,0,2.3-.2,13.25,13.25,0,0,0,11-15.4Z"
                        transform="translate(-0.35 0)"
                        fill="#fff"
                      />
                    </svg>
                    <a className="block" href="tel:+49 151 50 583 582">
                      +49 151 50 583 582
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-span-6 xl:col-span-7 bg-white p-4 pb-10 sm:py-6 sm:px-8 lg:p-10 flex items-center">
                <div className="w-full">
                  <AppContext.Consumer>
                    {({ modalOpened, error, setModalState, setErrorState }) => (
                      <ContactForm
                        modalOpened={modalOpened}
                        error={error}
                        setModalState={setModalState}
                        setErrorState={setErrorState}
                      ></ContactForm>
                    )}
                  </AppContext.Consumer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
