import React, { useState, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import useEmblaCarousel from "embla-carousel-react"
import { useRecursiveTimeout } from "./useRecursiveTimeout"

import { DotButton } from "./carouselButtons"
import "./../styles/slider.css"

const AUTOPLAY_INTERVAL = 7500

const Slider = () => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    speed: 10,
    dragFree: false,
  })

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  const autoplay = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, [embla])

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL)

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
    embla.on("pointerDown", stop)
    embla.on("pointerUp", play)
  }, [embla, onSelect, stop, play, setScrollSnaps])

  useEffect(() => {
    play()
  }, [play])

  const sliderImages = useStaticQuery(
    graphql`
      query {
        ueberdachung: file(
          relativePath: { eq: "slider/header_ueberdachung.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
        zaun: file(relativePath: { eq: "slider/header_zaun.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
        balkon: file(relativePath: { eq: "slider/header_balkon.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )

  let ueberdachung = getImage(sliderImages.ueberdachung)
  let zaun = getImage(sliderImages.zaun)
  let balkon = getImage(sliderImages.balkon)

  ueberdachung = convertToBgImage(ueberdachung)
  zaun = convertToBgImage(zaun)
  balkon = convertToBgImage(balkon)

  return (
    <div
      id="home"
      className="embla__slider w-full h-screen overflow-hidden"
      ref={viewportRef}
    >
      <div className="embla__container w-full h-screen flex">
        <div className="embla__slide w-full h-screen min-w-full relative">
          <BackgroundImage
            {...ueberdachung}
            Tag="section"
            className="h-full w-full flex items-center"
          >
            <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-96">
              <div className="flex mb-2">
                <h2 className="inline text-4xl md:text-5xl font-bold bg-blue-1 bg-opacity-80 p-5 px-6">
                  Eduard Smolarczyk
                </h2>
              </div>
              <div className="inline-block mr-7 bg-blue-3 bg-opacity-80 p-5 px-6">
                <p className="inline text-xl">
                  Ihr Ansprechpartner für alles rund ums Thema Metall- und
                  Eisenbau!
                </p>
              </div>
              <div className="mt-12 lg:mt-16">
                <div className="embla__dots">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
        <div className="embla__slide w-full h-screen min-w-full relative">
          <BackgroundImage
            {...zaun}
            Tag="section"
            className="h-full w-full flex items-center"
            image={zaun}
          >
            <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-96">
              <div className="flex mb-2">
                <h2 className="inline text-4xl md:text-5xl font-bold bg-blue-1 bg-opacity-80 p-5 px-6">
                  Individuelle Lösungen
                </h2>
              </div>
              <div className="inline-block mr-7 bg-blue-3 bg-opacity-80 p-5 px-6">
                <p className="inline text-xl">
                  Sie bestimmen jedes Detail bis hin zur
                  Ober&shy;flächen&shy;ver&shy;edelung!
                </p>
              </div>
              <div className="mt-12 lg:mt-16">
                <div className="embla__dots">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
        <div className="embla__slide w-full h-screen min-w-full relative">
          <BackgroundImage
            {...balkon}
            Tag="section"
            className="h-full w-full flex items-center"
            image={balkon}
          >
            <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-96">
              <div className="flex mb-2">
                <h2 className="inline text-4xl md:text-5xl font-bold bg-blue-1 bg-opacity-80 p-5 px-6">
                  Egal ob hoch oder tief
                </h2>
              </div>
              <div className="inline-block mr-7 bg-blue-3 bg-opacity-80 p-5 px-6">
                <p className="inline text-xl">
                  Wir fertigen für Sie die passende Lösung an!
                </p>
              </div>
              <div className="mt-12 lg:mt-16">
                <div className="embla__dots">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </div>
    </div>
  )
}

export default Slider
