import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import "./../styles/services.css"

class Services extends Component {
  render() {
    return (
      <div
        id="services"
        className="container max-w-screen-lg xl:max-w-screen-xl sm:mx-auto sm:px-6 pt-16 sm:pt-20 bg-white"
      >
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 sm:mb-10 text-gray-800">
          Services
        </h2>
        <StaticQuery
          query={graphql`
            {
              balkon: file(relativePath: { eq: "balkone/a.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
              ueberdachung: file(relativePath: { eq: "ueberdachungen/1.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
              zaun: file(relativePath: { eq: "tore/1.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
              treppe: file(relativePath: { eq: "treppen/1.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
              gelaender: file(relativePath: { eq: "gelaender/1.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
              blechgarage: file(relativePath: { eq: "blechgaragen/1.jpg" }) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, JPG]
                  )
                }
              }
            }
          `}
          render={data => (
            <div className="grid grid-cols-1 gap-11">
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last md:order-first">
                  <h3 className="text-4xl text-gray-800">
                    Balkone <wbr />& Terrassen
                  </h3>
                  <p className="text-base">
                    Balkone und Terrassen aus feuer&shy;verzinktem und
                    pul&shy;ver&shy;be&shy;schich&shy;te&shy;tem Stahl nach Maß
                    gefertigt.
                  </p>
                  <AnchorLink
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-3 focus:outline-none cursor-pointer"
                    onAnchorLinkClick={this.handleClick}
                    to="/balkone"
                  >
                    Mehr erfahren
                  </AnchorLink>
                </div>
                <div className="md:col-span-4 h-full">
                  <GatsbyImage
                    image={data.balkon.childImageSharp.gatsbyImageData}
                    alt="Terrasse mit Garten"
                    className="h-full block lg:max-h-96"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last">
                  <h3 className="text-4xl text-gray-800">
                    Über&shy;da&shy;chun&shy;gen
                  </h3>
                  <p className="text-base">
                    Vor der Tür, im Garten oder über dem Balkon, die
                    Über&shy;dach&shy;ungen werden zentimetergenau und nach
                    Ihren Vorstellungen maßgefertigt.
                  </p>
                  <AnchorLink
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-3 focus:outline-none cursor-pointer"
                    onAnchorLinkClick={this.handleClick}
                    to="/ueberdachungen"
                  >
                    Mehr erfahren
                  </AnchorLink>
                </div>
                <div className="md:col-span-4 h-full">
                  <GatsbyImage
                    image={data.ueberdachung.childImageSharp.gatsbyImageData}
                    alt="Terrasse mit Glasüberdachung"
                    className="h-full block lg:max-h-96"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last md:order-first">
                  <h3 className="text-4xl text-gray-800">Tore und Zäune</h3>
                  <p className="text-base">
                    Sie finden bei uns maßgefertigte Tore und Zäune für Ihr
                    Grundstück.
                  </p>
                  <AnchorLink
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-3 focus:outline-none cursor-pointer"
                    onAnchorLinkClick={this.handleClick}
                    to="/tore"
                  >
                    Mehr erfahren
                  </AnchorLink>
                </div>
                <div className="md:col-span-4 h-full">
                  <GatsbyImage
                    image={data.zaun.childImageSharp.gatsbyImageData}
                    alt="Garten mit Zaun"
                    className="h-full block lg:max-h-96"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last">
                  <h3 className="text-4xl text-gray-800">Treppen</h3>
                  <p className="text-base">
                    Im Treppen&shy;bau gibt es viele interessante
                    Mög&shy;lich&shy;keiten architek&shy;tonische
                    Ver&shy;bin&shy;dung&shy;en zum Wohnraum herzustellen, egal
                    ob Innen oder Außen.
                  </p>
                  <AnchorLink
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-3 focus:outline-none cursor-pointer"
                    onAnchorLinkClick={this.handleClick}
                    to="/treppen"
                  >
                    Mehr erfahren
                  </AnchorLink>
                </div>
                <div className="md:col-span-4 h-full">
                  <GatsbyImage
                    image={data.treppe.childImageSharp.gatsbyImageData}
                    alt="Treppe zur Eingangstür"
                    className="h-full block lg:max-h-96"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last md:order-first">
                  <h3 className="text-4xl text-gray-800">Geländer</h3>
                  <p className="text-base">
                    Wir bieten Ihnen eine große Auswahl verschiedener
                    Grund&shy;model&shy;le an, die Sie ganz
                    in&shy;di&shy;vi&shy;du&shy;ell mit verschie&shy;de&shy;nen
                    Ma&shy;te&shy;ria&shy;lien (Stahl, Edelstahl, Aluminium
                    etc.) nach Ihren Vorstel&shy;lungen gestalten können.
                  </p>
                  <AnchorLink
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-3 focus:outline-none cursor-pointer"
                    onAnchorLinkClick={this.handleClick}
                    to="/gelaender"
                  >
                    Mehr erfahren
                  </AnchorLink>
                </div>
                <div className="md:col-span-4 h-full">
                  <GatsbyImage
                    image={data.gelaender.childImageSharp.gatsbyImageData}
                    alt="Balkon mit Terrasse und Geländer"
                    className="h-full block lg:max-h-96"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-7 flex items-center card-shadow bg-white md:bg-gray-50 lg:max-h-96">
                <div className="md:col-span-3 py-7 md:py-9 px-7 xl:p-9 space-y-4 order-last">
                  <h3 className="text-4xl text-gray-800">
                    Blechgaragen <wbr />& Hallenbau
                  </h3>
                  <p className="text-base">
                    Wir bieten individuelle Lösungen für einfache Lagerhallen
                    oder Unter&shy;stän&shy;de.
                  </p>
                  <p className="text-base">
                    Noch befinden sich die ersten Projekte in der Bauphase.
                  </p>
                </div>
                <div className="md:col-span-4 h-full">
                  <div className="h-64 xs:h-72 max-h-64 xs:max-h-72 md:h-full md:max-h-96">
                    <BgImage
                      image={getImage(data.blechgarage)}
                      className="w-full h-full"
                    >
                      <div className="h-full w-full flex items-center justify-center bg-blue-2 bg-opacity-40">
                        <p className="text-center text-3xl font-bold text-white">
                          Bilder folgen in Kürze...
                        </p>
                      </div>
                    </BgImage>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

export default Services
