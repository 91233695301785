import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Infocard = () => {
  return (
    <div className="pt-16 sm:pt-24 bg-white">
      <StaticQuery
        query={graphql`
          query {
            infocard: file(relativePath: { eq: "infocard.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, JPG]
                  layout: CONSTRAINED
                )
              }
            }
          }
        `}
        render={data => (
          <BackgroundImage
            {...convertToBgImage(getImage(data.infocard))}
            Tag="section"
            className="h-full w-full flex items-center py-24 sm:py-36"
          >
            <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white">
              <h2 className="w-full text-4xl md:text-5xl mb-6">
                Erlauben Sie es sich zu träumen!
              </h2>
              <p className="max-w-4xl">
                Sie hatten schon immer genaue Vorstellungen z.B. von Ihrem
                Balkon, Terrasse oder Carport? Zum Beispiel modern oder
                rustikal, mit Klar- oder Milchglas Elementen und einer
                witterungsbeständigen Oberflächenveredelung? Sie bestimmen alle
                Veredelungen, um Ihr ganz persönliches Unikat zu verwirklichen.
              </p>
              <p className="mt-3 text-lg">
                Lassen Sie mich Ihre Wünsche umsetzen!
              </p>
            </div>
          </BackgroundImage>
        )}
      />
    </div>
  )
}

export default Infocard
