import React, { Component } from "react"

import { Formik, Field } from "formik"
import * as Yup from "yup"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

import ContactFormModal from "./contactFormModal"
import "./../styles/contactForm.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#11335f",
    },
  },
})
class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  handleCallback = error => {
    if (this.props.error) {
      this.props.setErrorState(false)
    }
    if (this.props.modalOpened) {
      this.props.setModalState(false)
    }
  }

  handleSuccess = () => {
    this.props.setErrorState(false)
    this.props.setModalState(true)
  }

  handleError = () => {
    this.props.setErrorState(true)
    this.props.setModalState(true)
  }

  recaptchaInstance

  resetRecaptcha = () => {
    this.recaptchaInstance.reset()
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            message: "",
            dsgvo: false,
            recaptcha: "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .max(15, "Darf nicht länger als 30 Zeichen sein")
              .required("Bitte tragen Sie Ihren Vornamen ein"),
            lastName: Yup.string()
              .max(20, "Darf nicht länger als 30 Zeichen sein")
              .required("Bitte tragen Sie Ihren Nachnamen ein"),
            email: Yup.string()
              .email("E-Mail-Adresse muss gültig sein")
              .required("Bitte tragen Sie Ihre E-Mail Adresse ein"),
            message: Yup.string()
              .min(50, "Muss mindestens 50 Zeichen lang sein")
              .required("Bitte beschreiben Sie Ihr Anliegen"),
            dsgvo: Yup.boolean()
              .required("Bitte akzeptiere die Datenschutzerklärung")
              .oneOf([true], "Bitte akzeptieren Sie die Datenschutzerklärung"),
            recaptcha: Yup.string().required(
              "Bitte füllen Sie das reCAPTCHA aus"
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            this.setState({ loading: true })
            axios({
              method: "post",
              url: "https://e-smolarczyk.de/php/contact.php",
              headers: { "content-type": "application/json" },
              data: values,
            })
              .then(result => {
                this.setState({ loading: false })
                this.props.setErrorState(false)
                this.props.setModalState(true)
                resetForm()
                this.resetRecaptcha()
              })
              .catch(error => {
                this.setState({ loading: false })
                this.props.setErrorState(true)
                this.props.setModalState(true)
                this.resetRecaptcha()
              })
          }}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <div className="grid mb-2.5 grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 md:grid-cols-1 lg:grid-cols-2">
                <ThemeProvider theme={theme}>
                  <Field
                    multiline
                    maxRows="2"
                    fullWidth
                    component={TextField}
                    id="firstName"
                    name="firstName"
                    label="Vorname *"
                    type="text"
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />

                  <Field
                    multiline
                    maxRows="2"
                    fullWidth
                    component={TextField}
                    id="lastName"
                    name="lastName"
                    label="Nachname *"
                    type="text"
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  <Field
                    multiline
                    maxRows="2"
                    fullWidth
                    component={TextField}
                    id="email"
                    name="email"
                    label="E-Mail *"
                    type="text"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <Field
                    multiline
                    maxRows="2"
                    fullWidth
                    component={TextField}
                    id="telephone"
                    name="telephone"
                    label="Telefonnummer"
                    type="text"
                    error={
                      formik.touched.telephone &&
                      Boolean(formik.errors.telephone)
                    }
                    helperText={
                      formik.touched.telephone && formik.errors.telephone
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telephone}
                  />
                  <Field
                    className="sm:col-span-2 md:col-span-1 lg:col-span-2"
                    multiline
                    maxRows={Infinity}
                    fullWidth
                    component={TextField}
                    id="message"
                    name="message"
                    label="Beschreiben Sie Ihr Anliegen *"
                    type="text"
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />

                  <div className="sm:col-span-2 md:col-span-1 lg:col-span-2">
                    <FormControlLabel
                      value="start"
                      control={
                        <Field
                          component={Checkbox}
                          color="primary"
                          id="dsgvo"
                          name="dsgvo"
                          inputProps={{ "aria-label": "Checkbox A" }}
                          type="checkbox"
                          error={formik.touched.dsgvo && formik.errors.dsgvo}
                          helpertext={
                            formik.touched.dsgvo && formik.errors.dsgvo
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.dsgvo}
                        />
                      }
                      label="Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden."
                      labelPlacement="end"
                    />
                    <div
                      className={
                        "mt-1.5 " +
                        (formik.touched.dsgvo && Boolean(formik.errors.dsgvo)
                          ? " block"
                          : "hidden")
                      }
                    >
                      <div
                        className={
                          "form-check-input custom-error-font text-red-500 " +
                          (formik.touched.dsgvo && Boolean(formik.errors.dsgvo)
                            ? " block"
                            : "hidden")
                        }
                      >
                        {formik.errors.dsgvo}
                      </div>
                    </div>
                  </div>
                </ThemeProvider>

                <div className="sm:col-span-2 md:col-span-1 lg:col-span-2">
                  <div className="h-20">
                    <ReCAPTCHA
                      ref={e => (this.recaptchaInstance = e)}
                      sitekey="6LcSB7YcAAAAAAWz8UqV5KWPXhDixDSJkusO0xFv"
                      render="explicit"
                      hl="de"
                      onExpired={response => {
                        formik.setFieldValue("recaptcha", response)
                      }}
                      onErrored={response => {
                        formik.setFieldValue("recaptcha", response)
                      }}
                      onChange={response =>
                        formik.setFieldValue("recaptcha", response)
                      }
                    />
                  </div>
                  <div
                    className={
                      "mt-1.5 " +
                      (formik.touched.recaptcha &&
                      Boolean(formik.errors.recaptcha)
                        ? " block"
                        : "hidden")
                    }
                  >
                    <div
                      className={
                        "form-check-input custom-error-font text-red-500 " +
                        (formik.touched.recaptcha &&
                        Boolean(formik.errors.recaptcha)
                          ? " block"
                          : "hidden")
                      }
                    >
                      {formik.errors.recaptcha}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className={
                      "inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150 bg-blue-3 focus:outline-none " +
                      (this.state.loading
                        ? "cursor-not-allowed"
                        : "cursor-pointer")
                    }
                  >
                    <svg
                      className={`-ml-1 mr-3 h-5 w-5 text-white ${
                        this.state.loading ? "animate-spin" : "hidden"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Absenden
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <ContactFormModal
          modalOpened={this.props.modalOpened}
          error={this.props.error}
          clickCallback={this.handleCallback}
        ></ContactFormModal>
      </div>
    )
  }
}

export default ContactForm
