import React from "react"

export const DotButton = ({ selected, onClick }) => (
  <button
    aria-label="Slide Button"
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
)
