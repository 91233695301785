import React, { Component } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutMe from "../components/aboutMe"
import Services from "../components/services"
import Slider from "../components/slider"
import Contact from "../components/contact"
import Infocard from "../components/infocard"

class Index extends Component {
  render() {
    return (
      <Layout>
        <Seo title="Metallbau Service - E. Smolarczyk" />
        <Slider />
        <AboutMe />
        <Infocard />
        <Services />
        <Contact />
      </Layout>
    )
  }
}

export default Index
